export const HEADER_DETAILS = [
    "Overview",
    "Highlights",
    "ProjectPlans",
    "Amenities",
    "Gallery",
    "Location"
]

export const BUDGET_DETAILS = [
    "The Budget Range You're Looking For?",
    "3.69 Cr* Onwards",
    "4Cr* Onwards",
    "Above 4.5 Cr*"
]

export const EMAILJS_DATA = {
    serviceId: 'service_xwx622j',
    templateId: 'template_23eq1ro',
    publicKey: 'n2PBBjuQAe22udJvp'
}