import React from 'react'

const Location = () => {
  return (
    <div class="map_div pt-md-5 pb-md-5 pt-4 pb-5 text-center" id="location">
    <div class="container">
        <div class="sec_head text-center mb-3 mb-md-4 wow fadeInUp">
            <h3>LOCATION MAP</h3>
        </div>
        <div class="map_wrap">
            <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Medchal,%20Secunderabad,%20Telangana%20501401%20+(Kotas%20Virginia%20City)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" class="wow fadeInUp mb-0" width="100%" height="400" style={{borderRadius:'20px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

    </div>
</div>
  )
}

export default Location