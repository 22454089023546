import React from 'react'
import proj1 from '../../components/utils/assets/images/overview_images/proj1.svg'
import proj2 from '../../components/utils/assets/images/overview_images/proj2.svg'
import proj3 from '../../components/utils/assets/images/overview_images/proj3.svg'
import proj4 from '../../components/utils/assets/images/overview_images/proj4.svg'

const ProjectHighlights = () => {
  return (
    <section className="proj_sec" id='highlights'>
                <div className="container py-md-5 py-4">
                    <div className="sec_head wow fadeInUp" >
                        <h3>KOTAS VIRGINIA CITY HIGHLIGHTS</h3>
                    </div>
                    <p className="mb-lg-3 mb-0 wow fadeInUp text-center">
                        Experience the finest living at the finest location.

                    </p>
                    <div className="row gx-md-5 gy-5 gy-md-5 mt-2 mx-auto">
                        <div className="col-lg-6">
                            <div className="proj_div d-flex justify-content-between align-items-center wow fadeInUp">
                                <img src={proj1} className="img-fluid" alt="icon" />
                                <div>
                                    <h5>
                                        The only ongoing KOTAS Virginia project of Medchal</h5>
                                    <p className="mb-0">
                                        With all the villas situated out of the city, Kotas Virginia offers a prime location advantage of Medchal!!


                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="proj_div d-flex justify-content-between align-items-center wow fadeInUp">
                                <img src={proj2} className="img-fluid" alt="icon" />
                                <div>
                                    <h5>Subscribe to uninterrupted views for life
                                    </h5>
                                    <p className="mb-0">
                                        Nestled amidst majestic hills and scenic lakes, life at Kotas Hillside Villas enables you to wake up to amazing views every day.


                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="proj_div d-flex justify-content-between align-items-center wow fadeInUp">
                                <img src={proj3} className="img-fluid" alt="icon" />
                                <div>
                                    <h5>4 BHK Triplex villas with a home theater</h5>
                                    <p className="mb-0">
                                        When every home in Medchal! is an apartment, own luxury like never before with Kotas Villas.


                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="proj_div d-flex justify-content-between align-items-center wow fadeInUp">
                                <img src={proj4} className="img-fluid" alt="icon" />
                                <div>
                                    <h5>
                                        Like-minded community
                                    </h5>
                                    <p className="mb-0">
                                        Stay successful being surrounded by like-minded people. Kotas Villas homes feature the perfect community for you!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-lg-5 mt-4">
                        {/* <btn className="btn wow fadeInUp" data-bs-toggle="modal" data-bs-target="#modalForm">
                            Download Brochure
                        </btn> */}
                    </div>
                </div>
            </section>
  )
}

export default ProjectHighlights