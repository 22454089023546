import React from 'react'
import overViewImage from '../../components/utils/assets/images/p4.jpg'

const OverView = () => {
  return (
    <>
        <section className="overview_sec py-4 py-md-5" id="overview">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-lg-0 mb-3">
                            <div className="over-txt">
                                <div className="sec_head wow fadeInUp">
                                    <h3 className="justify-content-start">OVERVIEW</h3>
                                </div>
                                <p className="wow fadeInUp">Looking for a serene and peaceful neighborhood to call home or a great investment opportunity? Look no further than our Gated Community Villa Project in Medchal, Hyderabad!

                                    KOTAS Virginia City features 113 stunning villas, ranging in size from 120 sqyds to 340 sqyds and built-up areas between 1500 sft to 3500 sft, so you're sure to find the perfect fit for your needs. Whether you're looking for a cozy retreat or a spacious family home, our villas have everything you need to live comfortably and luxuriously.

                                    Located in the growing North Hyderabad area, our Gated Community Villa Project is situated in the heart of a new IT corridor, making it an ideal place to live or invest. You'll enjoy easy access to top employers, schools, shopping centers, and entertainment options, all while living in a peaceful and secure community.
                                </p>
                                <p>Our villas are designed to offer the best of modern living, with a host of amenities and features that will make your life comfortable and convenient. From high-quality fixtures and finishes to spacious living areas and bedrooms, you'll love the attention to detail and care that has gone into creating these beautiful homes.</p>
                            </div>
                            {/* <btn className="btn wow fadeInUp" data-bs-toggle="modal" data-bs-target="#modalForm22">
                                Download Brochure
                            </btn> */}
                        </div>
                        <div className="col-lg-6 m-auto">
                            <div className="over-img wow fadeInUp">
                                <img src={overViewImage} alt="image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default OverView