import React from 'react'
import can from '../../components/utils/assets/images/imresizer-440.jpg'
import mainBannerImage from '../../components/utils/assets/images/imresizer-1920.jpg'
import EnquiryFormModal from '../ModalBox'

const Banner = () => {
  return (
    <>
        <section className=' banner_sec position-relative'>
                <div className="banner_img">
                    <picture>
                        <source media="(max-width:767px)" srcset={can} />
                        <img className="img-fluid w-100" src={mainBannerImage} alt="banner" />
                    </picture>
                </div>
                <div className='banner_caption mb-5 mb-md-0 d-flex align-items-end align-items-md-center top-0 start-0 end-0 bottom-0'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-5'>
                                <EnquiryFormModal />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Banner