import React from 'react'
import banquetHall from '../../components/utils/assets/images/amenities/banquet-hall.webp'
import kidsArea from '../../components/utils/assets/images/amenities/playarea.svg'
import swimmingPool from '../../components/utils/assets/images/amenities/swimming.svg'
import gym from '../../components/utils/assets/images/amenities/gym.svg'
import tennisCourt from '../../components/utils/assets/images/amenities/tennis.svg'
import security from '../../components/utils/assets/images/amenities/24_7 Security.webp'
import walkingTrack from '../../components/utils/assets/images/amenities/walking_track.svg'
import shuttle from '../../components/utils/assets/images/amenities/Badminton-Court.webp'
import basketBall from '../../components/utils/assets/images/amenities/Basket-ball-court.webp'
import landscape from '../../components/utils/assets/images/amenities/Landscape.png'
import Supermarket from '../../components/utils/assets/images/amenities/Supermarket.png'

const Aminities = () => {
  return (
    <section className="ameniti_sec py-md-5 py-4" id="amenities">
                <div className="container">
                    <div className="sec_head wow fadeInUp">
                        <h3 className="mb-5">AMENITIES</h3>
                    </div>
                    <div className="row gx-3 gy-4">
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={banquetHall} alt="icon" />
                                <p className="mb-0">Banquet Hall</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={kidsArea} alt="icon" />
                                <p className="mb-0">Kid’s Play Area</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={swimmingPool} alt="icon" />
                                <p className="mb-0">Swimming Pool</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={gym} alt="icon" />
                                <p className="mb-0"> Gym</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={tennisCourt} alt="icon" />
                                <p className="mb-0">Tennis Court</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={security} alt="icon" />
                                <p className="mb-0">24x7 Security</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={walkingTrack} alt="icon" />
                                <p className="mb-0">Walking Track</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={shuttle} alt="icon" />
                                <p className="mb-0">Shuttle Court (Out Door)</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={basketBall} alt="icon" />
                                <p className="mb-0">Basketball Practice Court</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={landscape} alt="icon" />
                                <p className="mb-0">Landscape</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="ameniti_div d-flex align-items-center wow fadeInUp">
                                <img src={Supermarket} alt="icon" />
                                <p className="mb-0">Supermarket</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
  )
}

export default Aminities