import React from 'react'
import MyCarousel from '../carousel'
import img1 from '../../components/utils/assets/images/carousel/1.jpg'
import img2 from '../../components/utils/assets/images/carousel/2.jpg'
import img3 from '../../components/utils/assets/images/carousel/3.jpg'
import img4 from '../../components/utils/assets/images/carousel/4.jpg'

const Gallery = () => {
  return (
    <section className='gallery_sec' id="gallery">
                <div className='container'>
                <div className="sec_head wow fadeInUp">
                        <h3 className="mb-5">GALLERY</h3>
                    </div>
                <MyCarousel images={[img1, img2, img3, img4]} />
                </div>
            </section>
  )
}

export default Gallery